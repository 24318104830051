$green: rgb(39, 105, 85);
$yellow: rgb(255, 213, 00);
$blue: rgb(0, 91, 187);
.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../assets/img/hero.jpg");
  padding: 1.5rem 1.7rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  h1 {
    font-size: 4rem;
    font-weight: 800;
    color: white;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
  }
  .box-wrapper {
    /* align-self: flex-start; */
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 599px) {
      display: none;
    }
    .box {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      .title {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;

        .block {
          width: 0%;
          height: inherit;
          background: $blue;
          position: absolute;
          animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
          display: flex;
        }

        h1 {
          -webkit-animation: mainFadeIn 2s forwards;
          -o-animation: mainFadeIn 2s forwards;
          animation: mainFadeIn 2s forwards;
          animation-delay: 1.6s;
          opacity: 0;
          display: flex;
          align-items: baseline;
          position: relative;

          span {
            width: 0px;
            height: 0px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;

            background: $yellow;
            -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92)
              forwards;
            animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
            animation-delay: 2s;
            margin-left: 5px;
            margin-top: -10px;
            position: absolute;
            bottom: 13px;
            right: -12px;
          }
        }
      }

      .role {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        /*      span {
          color: $yellow;
          font-weight: 600;
        } */
        .block {
          width: 0%;
          height: inherit;
          background: $yellow;
          position: absolute;
          animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
          animation-delay: 2s;
          display: flex;
        }

        h2 {
          animation: secFadeIn 2s forwards;
          animation-delay: 3.2s;
          opacity: 0;
        }
      }
    }
  }

  .navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      h5 {
        font-size: 1rem;
        font-weight: 500;
        color: white;
        align-self: flex-start;
      }
    }
    /*     .languages {
      display: flex;
      gap: 1rem;
      cursor: pointer;
      i {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
      }
    } */
  }
  &__title {
    transform: translateY(-50%);
    text-align: center;
    display: none;
    @media all and (max-width: 599px) {
      display: block;
    }
    /*  span {
      font-weight: 600;
      color: $blue;
    } */

    @media all and (max-width: 600px) {
      transform: translateY(0);
      h1 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.3;
      }
      h2 {
        font-size: 1.3rem;
      }
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .scroll {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 3px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        letter-spacing: 4.5px;
        color: $green;
      }
    }
    &__socials {
      display: flex;
      gap: 1rem;
      a {
        background: white;
        border-radius: 50%;
        width: 2.3rem;
        height: 2.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        &:hover {
          background: black;
        }
        &:hover svg {
          fill: white;
        }

        svg {
          width: 1rem;
          height: 1.1rem;
          fill: black;
          cursor: pointer;
          transition: 0.3s;
        }
      }
    }
  }
  a {
    text-transform: none;
    text-decoration: none;
  }
}

/* Keyframes */
@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: $blue;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: $blue;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: $blue;
    border: 0px solid #222;
    bottom: 13px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
