.project__card {
  margin: 0 0 14% 0;
  display: flex;
  justify-content: space-between;
  background: var(--section);
  border-radius: 10px;
  padding: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
  &__body {
    min-width: 50%;
    h2 {
      color: var(--projects-title);
    }
    a {
      color: var(--p);
    }

    p {
      width: 70%;
      text-align: justify;
      margin-bottom: 2.5rem;
      span {
        background: var(--highlight-text);
      }
    }

    .project__card__skills {
      width: 70%;
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      margin: 1rem 0 1.6rem 0;
    }
    .project__card__links {
      width: 69%;
      display: flex;
      justify-content: space-between;
      .github-link {
        position: relative;
        align-self: end;
        @media all and (min-width: 1000px) {
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 45%;
            background-color: var(--section);
            border-top: 1px solid #666;
            bottom: 0;
            overflow-y: hidden;
            z-index: 1;
          }
          &::after {
            content: "Code is here";
            text-align: center;
            font-size: 0.7rem;
            color: var(--har);
            display: block;
            position: absolute;
            width: 4rem;
            height: 2.2rem;
            border: 1px var(--git-animation-border) solid;
            border-radius: 3px 10px 6px 1px;
            opacity: 0;

            transition: opacity 700ms cubic-bezier(0.42, 0, 0.58, 1);
            top: -2.6rem;
            right: -4.7rem;
          }
          &:hover svg {
            transform: translateY(-1rem);
          }
          &:hover::after {
            opacity: 1;
          }
        }
        svg {
          fill: var(--h1);
          width: 30px;
          height: 30px;
          margin-top: 0.6rem;
          transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);
        }
      }
    }
  }
  &__picture {
    margin-top: 5%;
    width: 450px;
    height: 330px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    cursor: pointer;
    border-radius: 8px;

    &:hover img {
      opacity: 0;
    }

    img {
      width: 450px;
      height: 100%;
      border-radius: 8px;
      transition: 1s;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
        rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      /*  box-shadow: -20px 20px 15px rgba(0, 0, 0, 0.2),
        0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06); */
    }
  }
}

/* Media */
@media all and (max-width: 1300px) {
  .project__card {
    flex-direction: column;
    gap: 1rem;
    &__body {
      p {
        width: 100%;
      }
      .project__card__skills {
        @media all and (max-width: 1000px) {
          width: 100%;
        }
      }
      .project__card__links {
        width: 97%;
        .github-link {
          @media all and (max-width: 500px) {
            display: none;
          }
          img {
            @media all and (max-width: 999px) {
              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
    &__picture {
      width: 70%;
      height: auto;
      border: 1px solid rgba(0, 0, 0, 0.1);
      @media all and (max-width: 1000px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        box-shadow: none;
      }
    }
  }
}
