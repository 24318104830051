.success-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  &__window {
    min-width: 30%;
    min-height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: 0.6rem;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
    p {
      padding: 1rem 2rem;
      font-weight: 500;
      text-align: center;
      color: black;
    }
    button {
      background: white;
      color: black;
      text-transform: uppercase;
      text-align: center;
      padding: 1.5% 6%;
      margin-bottom: 1.2rem;
      border-radius: 6px;
      &:hover {
        cursor: pointer;
        background-color: black;
        color: white;
      }
    }
  }
}
