.contact {
  &__container {
    width: 50%;
    margin: 0 auto;
    @media all and (max-width: 1300px) {
      width: 70%;
    }
    .contact__title {
      margin: 0 0 50px 0;
      h2 {
        text-align: center;
      }
      p {
        width: 75%;
        margin: 20px auto;
        text-align: center;
        font-weight: 500;
      }
    }
    .contact__form {
      width: 100%;
      form {
        display: flex;
        flex-direction: column;
        .first__two {
          display: flex;
          justify-content: space-between;
          margin-bottom: 130px;
          gap: 2rem;
        }
      }
    }
  }
  @media all and (max-width: 1000px) {
    .contact {
      &__container {
        width: 90%;
        padding: 5rem 0;
        .contact__title {
          p {
            width: 100%;
          }
        }
        .contact__form {
          .first__two {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
}
