$green: #2e8b57;
$grass: rgb(102, 185, 92);
$light: #2e8b57;
$brown-black: #1d1d1d;

button.fancy {
  -webkit-appearance: button;
  background-color: transparent;
  border: 2px solid var(--h1);
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  float: right;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 1.25em 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  width: 16.8125rem;
  &.form {
    margin: 0 auto;
  }

  &::before {
    content: " ";
    width: 1.5625rem;
    height: 2px;
    background: var(--h1);
    top: 50%;
    left: 1.5em;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition: background 0.3s linear, width 0.3s linear;
    transition: background 0.3s linear, width 0.3s linear;
  }
  a {
    font-size: 1.125em;
    line-height: 1.33333em;
    padding-left: 2em;
    display: block;
    text-align: left;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--h1);
  }

  .top-key {
    height: 2px;
    width: 1.5625rem;
    top: -2px;
    left: 0.625rem;
    position: absolute;
    background: var(--section);
    -webkit-transition: width 0.5s ease-out, left 0.3s ease-out;
    transition: width 0.5s ease-out, left 0.3s ease-out;
  }

  .bottom-key-1 {
    height: 2px;
    width: 1.5625rem;
    right: 1.875rem;
    bottom: -2px;
    position: absolute;
    background: var(--section);
    -webkit-transition: width 0.5s ease-out, right 0.3s ease-out;
    transition: width 0.5s ease-out, right 0.3s ease-out;
  }
  .bottom-key-2 {
    height: 2px;
    width: 0.625rem;
    right: 0.625rem;
    bottom: -2px;
    position: absolute;
    background: var(--section);
    -webkit-transition: width 0.5s ease-out, right 0.3s ease-out;
    transition: width 0.5s ease-out, right 0.3s ease-out;
  }

  &:hover {
    background: black;
    background: var(--h1);

    &::before {
      width: 0.9375rem;
      background: var(--section);
    }

    a {
      color: var(--section);
      padding-left: 1.5em;
    }

    .top-key {
      left: -2px;
      width: 0px;
    }
    .bottom-key-1,
    .bottom-key-2 {
      right: 0;
      width: 0;
    }
  }
}
