.page-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: white;
  z-index: 20;

  div {
    width: 100%;
    height: 50vh;
    top: 0;
    position: absolute;
    background: black;
  }
}
