.skills-preview {
  text-align: left;
  &__icons {
    display: flex;
    gap: 0.8rem;
    &-icon {
      position: relative;
      width: 3rem;
      height: 3rem;
      border-radius: 0.35rem;
      box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.large {
        box-shadow: none;
      }
      .hover-description {
        position: absolute;
        /*  background: rgb(37, 202, 127); */
        padding: 0 5px;
        bottom: -25px;
        opacity: 0;
        user-select: none;
        transition: 0.3s;
        p {
          margin: 0;
          font-size: 0.8rem;
          color: black;
        }
      }
      &:hover .hover-description {
        opacity: 1;
      }
    }
    .link {
      cursor: pointer;
      transition: background-color 0.3s linear;
      &:hover {
        background-color: black;
      }
      &:active {
        transform: scale(0.9);
      }
      &:hover svg {
        fill: white;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: 0.3s ease;
      }
    }
    .large {
      width: 3rem;
      height: 3rem;
      svg {
        width: 100%;
        height: 100%;
        border-radius: 0.35rem;
        fill: var(--main-skills-fill);
      }
    }
  }
  @media all and (max-width: 500px) {
    display: none;
  }
}
