@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Slab:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*  background: #071013; */
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", "Open Sans", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --section: #1d1d1d;
  --project-section-background: black;
  --h1: white;
  --h2: white;
  --h3: #757c81;
  --projects-title: rgb(37, 202, 127);
  --project-card-background: #1d1d1d;
  --highlight-text: rgb(37, 202, 127);
  --p: #fff;
  --skill-background: rgba(255, 255, 255, 0.1);
  --main-skills-fill: rgb(37, 202, 127);
  --git-animation-border: rgba(255, 255, 255, 0.1);
}

[data-theme="light"] {
  --section: white;
  --project-section-background: rgb(249, 250, 251);
  --h1: #071013;
  --h2: #071013;
  --h3: #757c81;
  --projects-title: #071013;
  --project-card-background: #fff;
  --highlight-text: #fffae0;
  --p: rgb(52, 56, 66);
  --skill-background: rgba(0, 0, 0, 0.05);
  --main-skills-fill: rgb(73, 81, 87);
  --git-animation-border: rgba(0, 0, 0, 0.1);
}
