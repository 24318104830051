$green: rgb(37, 202, 127);
@mixin movingLabel {
  transform: translate(0, -20px) scale(0.85);
  color: $green;
}

.form__group {
  position: relative;
  width: 100%;
  margin: 0 auto 40px auto;
  &__input {
    width: 100%;
    display: block;
    position: relative;
    border: 0;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    letter-spacing: 1px;
    font-size: 1.1rem;
    color: var(--h1);

    &:focus {
      outline: none;
    }
    &:focus ~ .form__group__label {
      @include movingLabel();
    }
  }
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -5px) scale(1);
    color: rgb(123, 128, 154);
    font-size: 0.9rem;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.active {
      @include movingLabel();
    }
  }
}
