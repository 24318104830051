@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap");
.about {
  .container {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    .about__picture {
      flex: 0 0 auto;
      width: 35%;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-size: 0;
        transition: 1s;
        pointer-events: none;
      }
      &:hover img {
        transform: scale(1.02);
      }
      ul {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        list-style: none;
        background: rgba(255, 255, 255, 0);
        @media all and (max-width: 1300px) {
          display: none;
        }
      }
      li {
        background: #333;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: perspective(800px) rotateY(90deg);
        transition: 0.5s;
        transform-origin: left;
      }
      &:hover ul li {
        transform: perspective(800px) rotateY(0deg);
      }
      &:hover ul li:nth-child(1) {
        transition-delay: 0.1s;
      }

      &:hover ul li:nth-child(2) {
        transition-delay: 0.3s;
      }
      a {
        color: tomato;
        background: rgba(255, 255, 255, 0);
      }
      svg {
        fill: white;
        background: rgba(255, 255, 255, 0);
        width: 23px;
        height: 23px;
      }
      &:hover ul li svg:hover {
        fill: black;
      }
      &:hover ul li svg:hover li {
        background: white;
      }
    }
    .about__title {
      flex: 0 0 auto;
      width: 60%;
      display: flex;
      flex-direction: column;
    }
    p {
      margin-top: 15px;
      text-align: justify;
    }
    .about__previews {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

/* Media */
@media all and (max-width: 1300px) {
  .about {
    padding-top: 50px;
    .container {
      flex-direction: column;
      align-items: center;
      .about__title {
        width: 100%;

        text-align: center;
      }
      .about__picture {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        font-size: 0;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          z-index: 500;
        }
      }
    }
  }
}
