h1 {
  color: var(--h1);
  font-weight: 700;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  margin: 0;
}

h2 {
  font-weight: bold;
  font-size: 3rem;
  color: var(--h1);
  letter-spacing: 0.1rem;
  margin: 0;
}

h3 {
  color: var(--h3);
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 1px;
  margin: 0;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--h1);
  text-transform: uppercase;
}

h5 {
  padding: 0.35rem 0.7rem;
  color: var(--h1);
  font-weight: normal;
  font-size: 0.8rem;
  margin: 0;
}
h6 {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--h1);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 0.6rem 0 0.7rem;
}

p {
  color: var(--p);
  line-height: 1.6em;
  font-weight: 400;
  font-size: 1rem;
  margin: 20px 0;
}

section {
  background: var(--section);
  padding: 100px 0;
  @media all and (max-width: 1000px) {
    padding: 50px 0;
  }
  @media all and (max-width: 500px) {
    padding: 25px 0;
  }
}
.container {
  width: 1200px;
  margin: auto;
  position: relative;
  @media all and (max-width: 1300px) {
    width: 85%;
  }
  @media all and (max-width: 500px) {
    width: 95%;
  }
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: rgb(135, 142, 153);
}

@media all and (max-width: 500px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  p {
    font-size: 1rem;
  }
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: #909090;
  background-repeat: no-repeat;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #212123;
}

::-webkit-scrollbar-thumb:hover {
  background: #606060;
}
